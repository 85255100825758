import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {loadSingleProduct} from '../../core/actions';

import {PRODUCT_CODE} from "../../core/consts";
import {getCodebook} from "../../core/codebook";

import ProductCategory from './productCategory';

const ProductList = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state?.product?.categories) || [];
    const notActiveCategory = useSelector(state => state?.product?.notActiveCategory) || [];

    useEffect(() => {
        dispatch(getCodebook('CB_PartyProductInstanceRoleType'))
    }, [dispatch]);

    const handleItemClicked = (product) => {
        dispatch(loadSingleProduct(product.cgpId));
    };

    const eligibleCategories = () => {
       return categories?.filter(c => PRODUCT_CODE[c.id]) || []
    };

    return (
        <>
            {eligibleCategories().sort((a, b) => PRODUCT_CODE[a.id]?.rank - PRODUCT_CODE[b.id]?.rank)
                    .map(category => <ProductCategory category={category} key={category.id} productClicked={handleItemClicked}/>)}
            {notActiveCategory && notActiveCategory.length > 0 ?
                <ProductCategory productClassName="inactive" category={{id: 'INACTIVE', list: notActiveCategory}} productClicked={handleItemClicked}/>
            : null}
        </>
    )
};

export default ProductList; 