export function updateClientInfo(state, action){
    return {...state, loading: false,
        data: {...state.data,
            addresses: updateAddresses(state, action),
            clientAlerts:action.data.clientAlerts,
            clientAgreements:action.data.clientAgreements,
            clientContacts:action.data.clientContacts,
            crsCertified:action.data.crsCertified,
            privateBankerUsername:action.data.privateBankerUsername,
            channels: action.data.channels
        }};
}

function updateAddresses(state, action){
    if(!state?.data?.addresses){
        return action.data.addresses;
    }

    return state.data.addresses;
}