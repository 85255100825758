import {useSelector} from "react-redux";
import ModalBody from "reactstrap/es/ModalBody";
import React from "react";
import Modal from "reactstrap/es/Modal";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Loading from "../form/loading";

const CaseLaunch = () => {

    const starting = useSelector(state => state?.casetype?.starting);

    return (
        <Modal size="lg" isOpen={starting} >
            <ModalBody className="py-5">
                <Row className="my-5 text-center">
                    <Col md={12} className="pb-3">
                        <Loading />
                    </Col>
                    <Col md={12}>
                        <h2>Zahajuji požadavek, prosím o strpení</h2>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
};

export default CaseLaunch;