import {
    ALERT_DANGER,
    CLIENT_ENTITY_TYPES,
    PRODUCT_ENTITY_TYPES,
    SEARCH_QUERY_ACTION, SEARCH_QUERY_ACTION_FAILED,
    SEARCH_QUERY_CHANGED, STARTER_SEARCH_COMPLETE_EVENT,
} from "../consts";
import Resource from "../serverresource";
import {
    clientStartLoading, loadByExpression,
    loadClient,
    loadProduct, loadProducts,
    productDetailStartLoading, setClientCleared, setProductCleared
} from "../actions";
import {fireEvent} from "./eventActions";
import {getCodebook, getCodebookValue} from "../codebook";
import {fireShowAlert} from "./alertActions";


export const searchQueryChanged = (text) => (
    {type: SEARCH_QUERY_CHANGED, text}
);

export const searchQueryAction = (data, loading, loaded, failed) => (
    {type: SEARCH_QUERY_ACTION, data, loading, loaded, failed}
);

export const searchQueryActionFailed = () => (
    {type: SEARCH_QUERY_ACTION_FAILED}
);

/**
 * Function for recognition
 *
 * @returns {Promise<any | never>}
 */
export const recognize = (skipLoadByExpression, skipLoadClientProducts) => {
    return (dispatch, getState) => {
        let body = {
            source: getState().search?.query ? getState().search?.query.trim() : '',
            allowFulltext: false
        };

        dispatch(searchQueryAction([], true));
        dispatch(setProductCleared());
        dispatch(setClientCleared());
        return fetch(Resource.getSesApi() + "/recognition?test=true", {
            headers: {
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(entities => {
                    entities = entities.filter(e => isPermittedType(e));
                    dispatch(fireEvent(STARTER_SEARCH_COMPLETE_EVENT, entities));
                    if (entities && entities.length === 1) {
                        let foundEntity = entities[0];
                        if (isClientType(foundEntity)) {
                            dispatch(searchQueryAction([], false, true));
                            return dispatch(searchClient(foundEntity, skipLoadByExpression, skipLoadClientProducts));
                        } else if (isProductType(foundEntity)) {
                            dispatch(searchQueryAction([], false, true));
                            return dispatch(searchProduct(foundEntity));
                        } else {
                            dispatch(searchQueryAction(entities, false, true));
                            return Promise.resolve();
                        }
                    } else if(entities && entities.some(e => isProductType(e))){
                        dispatch(getCodebook("CB_BackendProduct"))
                            .then((cbBackendProduct) => {
                                entities.filter(e => isProductType(e))
                                    .forEach(e => e.backendProductName = getCodebookValue(cbBackendProduct, e?.detail?.beProduct?.backendProductId));
                                dispatch(loadByExpression());
                                dispatch(searchQueryAction(entities, false, true))
                                return Promise.resolve();
                            });
                    } else {
                        dispatch(loadByExpression());
                        dispatch(searchQueryAction(entities, false, true))
                        return Promise.resolve();
                    }
                }
            ).catch(err => {
                dispatch(searchQueryActionFailed());
                dispatch(fireShowAlert("Vyhledávání selhalo", ALERT_DANGER))
                return Promise.reject(err);
            })
    }
};

export const searchProduct = (entity) => {
    return (dispatch) => {
        dispatch(productDetailStartLoading());
        return dispatch(loadProduct(entity.ref))
    }
};

export const searchClient = (entity, skipLoadByExpression, skipLoadClientProducts) => {
    return (dispatch) => {
        dispatch(clientStartLoading());
        if(!skipLoadClientProducts) {
            dispatch(loadProducts(entity.ref, entity.type === "COMPANY"));
        }
        return dispatch(loadClient(entity.ref, entity.type === "PERSON", skipLoadByExpression));
    }
};

const isProductType = (entity) => {
    return PRODUCT_ENTITY_TYPES.some(pet => pet === entity.type)
};

const isClientType = (entity) => {
    return CLIENT_ENTITY_TYPES.some(pet => pet === entity.type)
};

const isPermittedType = (entity) => {
    return isClientType(entity) || isProductType(entity)
};