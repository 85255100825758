import {
    ADFS_ACCESS_EXPIRING, ADFS_ACCESS_EXPIRING_NOTED,
    ADFS_AUTH_FAILED, AUTH_INFO_FAILED,
    AUTH_INFO_INVALIDATE,
    AUTH_INFO_LOADED, AUTH_INFO_LOADING, AUTH_REFRESH_FAILED,
    AUTH_REFRESHED,
    AUTH_REFRESHING
} from "../core/consts";

const initialState = {
    expiresIn: null,
    expireTime: null,
    accessToken: null,
    refreshExpiresIn: null,
    refreshToken: null,
    refreshExpireTime: null,
    idToken: null,
    scope: null,
    tokenType: null,
    accessTime: null,
    adfsFailed: false,//adfs redirect url result check
    adfsAccessTokenLoading: false,
    adfsAccessTokenFailed: false,//adfs access token handling check
    adfsAccessRefreshing: false,
    adfsAccessRefreshingFailed: false,//adfs access token refresh handling check
    adfsAccessExpiring: false,
    adfsAccessExpiringNoted: false
};

const authorizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADFS_AUTH_FAILED:
            return {...state, adfsAuthFailed: true};
        case AUTH_INFO_LOADING:
            return {...state, adfsAccessTokenLoading: true, adfsAccessTokenFailed: false};
        case AUTH_INFO_LOADED:
            return {...state,
                expiresIn: action.data.expires_in,
                accessToken: action.data.access_token,
                refreshExpiresIn: action.data.refresh_token_expires_in,
                refreshToken: action.data.refresh_token,
                idToken: action.data.id_token,
                scope: action.data.scope,
                tokenType: action.data.token_type,
                accessTime: new Date(),
                expireTime: getExpirationDate(action.data.expire_time, action.data.expires_in),
                refreshExpireTime: getExpirationDate(action.data.refresh_expire_time, action.data.refresh_token_expires_in),
                adfsAccessTokenLoading: false,
                adfsAccessTokenFailed: false,
            };
        case AUTH_INFO_FAILED:
            return {...state, adfsAccessTokenLoading: false, adfsAccessTokenFailed: true};
        case AUTH_INFO_INVALIDATE:
            return initialState;
        case AUTH_REFRESHING:
            return {...state, adfsAccessRefreshing: true, adfsAccessRefreshingFailed: false};
        case AUTH_REFRESHED:
            return {...state,
                adfsAccessRefreshing: false,
                adfsAccessRefreshingFailed: false,
                accessToken: action.data.access_token,
                expiresIn: action.data.expires_in,
                idToken: action.data.id_token,
                expireTime: getExpirationDate(null, action.data.expires_in)};
        case AUTH_REFRESH_FAILED:
            return {...state, adfsAccessRefreshing: false, adfsAccessRefreshingFailed: true};
        case ADFS_ACCESS_EXPIRING:
            return {...state, adfsAccessExpiring: true};
        case ADFS_ACCESS_EXPIRING_NOTED:
            return {...state, adfsAccessExpiringNoted: true};
        default:
            return state;
    }
};

const getExpirationDate = (date, expiresIn) => {
    if(date){
        return date;
    }

    let futureInMillis = new Date().getTime() + expiresIn *1000;
    return new Date(futureInMillis);
};

export default authorizationReducer;
