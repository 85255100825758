import React, { useEffect, useState } from 'react';
import {Row, Col, Button} from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import Filter from "./comp/filter";
import CaseTypes from "./comp/caseTypes";
import Search from "./comp/search";
import Navbar from "reactstrap/es/Navbar";
import NavbarBrand from "reactstrap/es/NavbarBrand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavbarText from "reactstrap/es/NavbarText";
import NavItem from "reactstrap/es/NavItem";
import NavLink from "reactstrap/es/NavLink";
import Nav from "reactstrap/es/Nav";
import ClientDetail from "./comp/client/clientDetail";
import LoadingLayer from "./comp/form/loadinglayer";
import { useDispatch, useSelector } from "react-redux";
import ProductDetail from "./comp/product/productDetail";
import CaseLaunch from "./comp/cases/caseLaunch";
import ClientList from "./comp/client/clientList";
import { fireLogin, preferGtiSelected, userFromTabletConfirmed } from "./core/actions/userActions";
import { fireViewportInfo } from "./core/actions/viewportActions";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle"
import DebugModal from "./comp/modals/debugmodal";
import ReportIncidentModal from "./comp/modals/reportincidentmodal";
import DialogInfo from "./comp/feedback/DialogInfo";
import FloatingInfo from "./comp/feedback/FloatingInfo";
import {deleteOboContext, loadByExpression, loadCaseTypes} from "./core/actions";
import CountdownInfo from "./comp/feedback/CountdownInfo";
import AlertInfo from "./comp/feedback/AlertInfo";
import { chainActions } from "./core/actions/appActions";
import { fireLoadEnvironment } from "./core/actions/environmentActions";
import StartSingleCheckbox from "./comp/form/startSingleCheckbox";
import RepresentativeList from "./comp/representative/representativeList";
import TabletModal from './comp/modals/tabletmodal';

library.add(fas);

const App = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV;

    const searchLoading = useSelector(state => state.search?.loading);
    const clientLoading = useSelector(state => state.client?.loading);
    const clientFailed = useSelector(state => state.client?.failed);
    const productLoading = useSelector(state => state.product?.loading || state.product?.activeProductsLoading || state.product?.historyProductsLoading || state.product?.cardProductsLoading);
    const productFailed = useSelector(state => state.product?.failed);
    const relationsLoading = useSelector(state => state.product?.relationsLoading);
    const identity = useSelector(state => state.user?.identity);
    const identityLoading = useSelector(state => state.user?.loading);
    const identityLoadingError = useSelector(state => state.user?.error);
    const caseTypesLoadingError = useSelector(state => state.casetype?.failed);
    const expressionFailed = useSelector(state => state.casetype?.expressionFailed);
    const expireTime = useSelector(state => state.authorization.expireTime);
    const fromTablet = useSelector(state => state?.user?.fromTablet);
    const fromTabletConfirmed = useSelector(state => state?.user?.fromTabletConfirmed);

    const clientData = useSelector(state => state?.client?.data);
    const productData = useSelector(state => state?.product?.data);

    const gtiPreferred = useSelector(state => state?.user?.preferGti);

    const [debugOpen, setDebugOpen] = useState(false);
    const [reportIncidentOpen, setReportIncidentOpen] = useState(false);
    const [style, setStyle] = useState(null);
    const [called, setCalled] = useState(false);

    const header = document.getElementById('header');
    const search = document.getElementById('search');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fireLogin());
        dispatch(fireLoadEnvironment());
        dispatch(fireViewportInfo(window.innerWidth, window.innerHeight));
    }, [dispatch]);

    useEffect(() => {
        if (identity && !called) {
            dispatch(chainActions());
            setCalled(true);
        }
    }, [dispatch, identity, called]);

    useEffect(() => {
        if(header && search){
            let totalHeight = header.offsetHeight + search.offsetHeight + 5;
            setStyle({
                height: "calc(100vh - " + totalHeight + 'px)',
                overflowY : 'auto',
                overflowX : 'hidden'
            })
        }
    }, [header, search, clientData, productData]);

    const render = () => {
        if (!productData && !clientData) {
            if(productFailed){
                return <AlertInfo color="warning" label="Vyhledávání produktu se nezdařilo" hideClose={true} className="mt-4"/>
            }else if(clientFailed){
                return <AlertInfo color="warning" label="Vyhledávání klienta se nezdařilo" hideClose={true} className="mt-4"/>
            }
            return <></>
        }

        if (!productData && clientData) {
            return <ClientDetail className="mt-3"/>
        }

        if (productData && !clientData) {
            return (
                <>
                        <ProductDetail className="mt-3"/>
                        <RepresentativeList />
                        <ClientList className="mt-3"/>
                </>
            )
        }

        if (productData.timestamp < clientData.timestamp) {
            return (
                <>
                        <ProductDetail className="mt-3"/>
                        <ClientDetail className="mt-3"/>
                        <RepresentativeList />
                </>
            )
        } else {
            return (
                <>
                        <ClientDetail className="mt-3"/>
                        <RepresentativeList />
                        <ProductDetail className="mt-3"/>
                        <Col md={12} className="px-0">
                            <hr/>
                        </Col>
                        <ClientList/>
                </>
            )
        }
    };

    const toggleDebugDialog = () => {
        setDebugOpen(!debugOpen);
    };

    const toggleReportIncidentDialog = () => {
        setReportIncidentOpen(!reportIncidentOpen);
    };

    const relogUser = () => {
        dispatch(fireLogin())
    };

    const reloadCaseTypes = () => {
        dispatch(loadCaseTypes())
    };

    const refreshCaseTypes = () => {
        dispatch(loadByExpression())
    };

    const onBtiClick = () => {
        dispatch(preferGtiSelected());
    }

    const hideFromTabletModal = () => {
        dispatch(userFromTabletConfirmed());
    }

    const handleDeleteObo = () => {
        dispatch(deleteOboContext(identity?.username)).then(r => {
            window.sessionStorage.clear();
            window.location.reload();
        });
    }

    return (
        <React.Fragment>
            <Navbar id="header" className="navbar-dark navbar-starter shadow-sm py-3">
                <NavbarBrand className="text-uppercase font-weight-bold size display-4"
                             href="/">Service Starter</NavbarBrand>
                <Nav className="mr-3" navbar>
                    <NavItem className="border-left">
                        <NavLink className="ml-2">@Powered by Smartcase</NavLink>
                    </NavItem>
                </Nav>
                <Nav className="mr-3" navbar>
                    <NavItem className="border-left">
                        <NavbarText className="ml-2 selectable" onClick={toggleReportIncidentDialog}>
                            <FontAwesomeIcon icon={faExclamationTriangle}
                                             color="primary"
                                             className="text-white mx-2"
                                             size="lg"/>
                            Nahlásit problém
                        </NavbarText>
                    </NavItem>
                </Nav>
                <Nav className="mr-auto d-none" navbar>
                    <NavItem>
                        <NavLink>
                            <StartSingleCheckbox
                                id="preferGti"
                                className="d-inline-block"
                                label="BTI"
                                value={gtiPreferred}
                                onChange={onBtiClick}/>
                        </NavLink>
                    </NavItem>
                </Nav>
                {REACT_APP_ENV !== 'PROD' ?
                    <NavbarText className="mx-auto">
                        <span className="text-warning font-weight-bold mr-2">{REACT_APP_ENV}</span>
                        <span>Klient1: 8103300205</span>&nbsp;
                        <span>Klient2: 6306280024</span>&nbsp;
                        <span>Hypotéka: 4858588843</span>&nbsp;
                        <span>Spoření: 1031005185</span>&nbsp;
                        <span>Společnost: 25411438</span>&nbsp;
                        <span>Společnost2: 26620090</span>
                        <CountdownInfo expireTime={expireTime} className="d-inline-block text-white ml-3"/>
                        <Button onClick={handleDeleteObo} className="bg-primary d-inline-block ml-3">Delete OBO context</Button>
                    </NavbarText> : null}
                <NavbarText className="font-weight-bold font-size-12">
                    {identity?.fullName}
                    <FontAwesomeIcon icon={faEye} color="primary" className="text-white mx-2 selectable" size="lg"
                                     onClick={toggleDebugDialog}/>
                </NavbarText>
            </Navbar>
            <main className="main starter">
                <Row className='top-area mx-0'>
                    <Col id="searchSection" md="3">
                        <LoadingLayer
                            isLoading={searchLoading || clientLoading || productLoading || relationsLoading}>
                            <Search style={style}/>
                            <Row style={style}>
                                <Col md={12}>
                                    {render()}
                                </Col>
                            </Row>
                        </LoadingLayer>
                    </Col>
                    <Col md="9">
                        <Filter/>
                        <CaseTypes/>
                    </Col>
                </Row>
                <CaseLaunch/>
            </main>

            <FloatingInfo/>

            <DialogInfo color="info" isOpen={identityLoading} icon="info"
                        header="Probíhá přihlášení"
                        info="Prosíme o strpení."/>

            <DialogInfo color="danger" isOpen={identityLoadingError && !identityLoading} icon="warning"
                        header="Je nám líto, ale přihlášení selhalo"
                        info="Během vašeho přihlášení došlo k problému. Můžete to ale zkusit znovu. Pokud problém přetrvává, kontaktujte prosím podporu."
                        onSubmit={relogUser}
                        submitLabel="Přihlásit se znovu"/>

            <DialogInfo color="danger" isOpen={caseTypesLoadingError} icon="warning"
                        header="Je nám líto, ale nenačetli jsme požadavky"
                        info="Bohužel se nám nepodařilo načíst požadavky. Můžete to ale zkusit znovu. Pokud problém přetrvává, kontaktujte prosím podporu."
                        onSubmit={reloadCaseTypes}
                        submitLabel="Načíst požadavky znovu"/>

            <DialogInfo color="danger" isOpen={expressionFailed} icon="warning"
                        header="Je nám líto, ale neaktualizovali jsme požadavky"
                        info="Bohužel se nám nepodařilo aktualizovat požadavky. Můžete to ale zkusit znovu. Pokud problém přetrvává, kontaktujte prosím podporu."
                        onSubmit={refreshCaseTypes}
                        submitLabel="Aktualizovat požadavky"/>

            <TabletModal isOpen={fromTablet && !fromTabletConfirmed} onExit={hideFromTabletModal} />
            <DebugModal isOpen={debugOpen} onExit={toggleDebugDialog} />
            <ReportIncidentModal isOpen={reportIncidentOpen} onExit={toggleReportIncidentDialog} />
        </React.Fragment>
    );
};

export default App;
