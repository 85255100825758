import fetchIntercept from "fetch-intercept";
import {PRESELECTED_BOTH, PRESELECTED_CGPID, PRESELECTED_CLUID, PRESELECTED_NONE} from "./consts";

export function filterIt(arr, searchKey) {
    if (!searchKey) {
        return arr;
    }
    if (!arr) {
        return undefined;
    }

    return arr.filter(item => contains(item.filter, searchKey));
}

function contains(filter, searchText) {
    return filter.some(f => f?.includes(normalizeForSearch(searchText)));
}

export function normalizeForSearch(value) {
    return value?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")?.toLowerCase();
}

export function extractParamFromURL(param) {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
}

export function removeParamsFromUrl(params){
    let url = new URL(window.location.href);
    params.forEach(p => url.searchParams.delete(p));
    return url.href;
}

export function analyzeUrl(){
    let cluid = extractParamFromURL('cluid');
    let cgpId = extractParamFromURL('cgpId');

    if(cluid && cgpId){
        return PRESELECTED_BOTH;
    }else if(cluid){
        return PRESELECTED_CLUID;
    }else if(cgpId){
        return PRESELECTED_CGPID;
    }else{
        return PRESELECTED_NONE;
    }
}

export function registerInterceptor(accessToken) {
    fetchIntercept.clear();
    fetchIntercept.register({
        request: (url, config) => {
            if (!config) {
                config = {};
            }
            if (!config.headers) {
                config.headers = {};
            }
            config.headers['Authorization'] = 'Bearer ' + accessToken;
            config.headers["web-api-key"] = process.env.REACT_APP_WEBAPI_KEY;
            return [url, config];
        }
    });
}