import {
    ALERT_HIDE,
    ALERT_SHOW

} from '../core/consts';

const initialState = {
    alerts:[]
};

const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_SHOW:{
            let alerts = [...state.alerts];
            alerts.push(action.alert);
            return {...state, alerts: alerts}
        }
        case ALERT_HIDE:{
            if(state.alerts.some(a => a.id === action.alert.id)) {
                return {...state, alerts: state.alerts.filter(a => a.id !== action.alert.id)}
            }
            return {...state}
        }

        default:
            return state;
    }
};

export default alertsReducer;