import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import React from "react";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";

const EmptyInfo = ({icon, text}) => {

    return (
        <Row className="text-center">
            <Col>
                <Row className="py-3">
                    <Col>
                        <FontGeorgeIcon icon={icon} width={50} height={50}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="font-size-13">{text}</span>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default EmptyInfo;