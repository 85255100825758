import React from 'react';
import {useSelector} from 'react-redux';
import AlertInfo from "./AlertInfo";

const FloatingInfo = () => {
    const alerts = useSelector(state => state?.alerts.alerts);

    return (
        <div className="floating-info-container">
            {alerts.map(a => {
                return (
                    <AlertInfo id={a.id}
                               color={a.color}
                               label={a.label}
                               actionLabel={a.actionLabel}
                               timeout={a.timeout}
                               hideClose={false}
                               onClick={a.onClick}/>
                )
            })}
        </div>
    )
};

export default FloatingInfo;