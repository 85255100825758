import {buildEvent} from "../helper/actionHelper";
import Resource from "../serverresource";
import {fireShowAlert} from "./alertActions";
import {ALERT_WARNING} from "../consts";

export function fireEvent(type, entities, cidla) {
    return (dispatch, getState) => {
        let event = buildEvent(type, getState, entities, cidla);
        return fetch(Resource.getSesApi() + "/event", {
            headers: {"Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(event)
        }).catch((error) => {
            dispatch(fireShowAlert("Nedaří se odeslat informace ke zlepšování uživatelského prostředí", ALERT_WARNING));
            console.error(error);
        })
    };
}