import { combineReducers } from 'redux';
import client from '../reducers/client';
import product from '../reducers/product';
import codebook from '../reducers/codebook';
import casetype from '../reducers/casetype';
import search from '../reducers/search';
import user from '../reducers/user';
import viewport from '../reducers/viewport';
import authorization from '../reducers/authorization';
import alerts from '../reducers/alerts';
import reportIncident from "../reducers/reportIncident";
import environment from "../reducers/environment";

const rootReducer = combineReducers({
    client,
    product,
    codebook,
    casetype,
    search,
    user,
    viewport,
    authorization,
    alerts,
    reportIncident,
    environment
  });

export default rootReducer;