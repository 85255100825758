import {REPORT_INCIDENT_STARTED} from "../core/consts";
import {REPORT_INCIDENT_FAILED} from "../core/consts";
import {REPORT_INCIDENT_FINISHED} from "../core/consts";
import {REPORT_INCIDENT_CLEARED} from "../core/consts";

const initialState = { loading: false, error: false, errorText: ''};

const reportIncidentReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_INCIDENT_STARTED:
            return {...state, loading: true};
        case REPORT_INCIDENT_FAILED:
            return {...state, loading: false, error: true, errorText: action.err};
        case REPORT_INCIDENT_FINISHED:
            return {...state, loading: false, error: false};
        case REPORT_INCIDENT_CLEARED:
            return {...state, errorText: ''};
        default:
            return state;
    }
};

export default reportIncidentReducer;
