/**
 * Function that creates default data object
 *
 * @returns {{data: Array, isLoading: boolean, hasError: boolean, errorMsg: null, isLoaded: boolean}}
 */
export function dataBuild(initialDataType){
    return {
        data:initialDataType,
        loading: false,
        loaded: false,
        error: false,
        errorMsg: null,
        errorType: null
    }
}

/**
 * Function for store manipulation over data object for event data LOADING. Data object HAS to be on root level.
 *
 * @param state
 * @param attr
 * @returns new state
 */
export function dataLoading(state, attr){
    return {...state, [attr]:
            {...state[attr], loading: true, loaded: false, error: false}
    };
}

/**
 * Function for store manipulation over data object for event data LOADED. Data object HAS to be on root level.
 *
 * @param state
 * @param attr
 * @param data
 * @returns new state
 */
export function dataLoaded(state, attr, data){
    return {...state, [attr]:
            {...state[attr], loading: false, loaded: true, error: false, data: data}
    };
}

/**
 * Function for store manipulation over data object for event data LOADING_ERROR. Data object HAS to be on root level.
 *
 * @param state
 * @param attr
 * @param error
 * @returns newState
 */
export function dataLoadingFailed(state, attr, error, errorType){
    return {...state, [attr]:
            {...state[attr], loading: false, loaded: true, error: true, data: [], errorMsg: error, errorType}
    };
}

export function getData(data){
    return data.data;
}

export function getErrorMsg(data){
    return data.errorMsg;
}

export function isError(data){
    return data.error;
}

export function isLoading(data){
    return data.loading;
}

export function isLoaded(data){
    return data.loaded;
}