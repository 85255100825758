import React, {useEffect, useRef, useState} from "react";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";

/**
 * Basic component to be used with inputs & such for reseting them.
 */
const StartClear = ({id, title, parentHeight, onClear, clearable, showReset, className}) => {

    const [height, setHeight] = useState(0);
    const wrapperRef = useRef();

    useEffect(() => {
        setHeight(wrapperRef.current.clientHeight);
    }, [wrapperRef]);

    const getClasses = () => {
        let classes = [];
        classes.push(className ? className : '');
        classes.push((clearable && showReset) ? 'visible' : 'invisible');
        classes.push('input-clear-button');
        classes.push(onClear ? 'selectable' : '');
        return classes.join(" ");
    };

    const resolveVerticalPosition = () => {
        if(parentHeight){
            return ((parentHeight / 2) - (height / 2)) + 'px';
        }

        return '0px'
    };

    const clearPosition = {
        top: resolveVerticalPosition(),
        right: '50px'
    };

    return (
        <React.Fragment>
                <span id={id}
                      className={getClasses()}
                      title={title}
                      onClick={(e) => onClear(e)}
                      style={clearPosition}
                      ref={wrapperRef}>
                    <FontGeorgeIcon icon="error-xl" width={30} height={30}/>
                </span>
        </React.Fragment>
    );
};

export default StartClear;