/**
 *
 * @param client {PersonDto|CompanyDto|null} - object holding data of selected company/person
 * @param authorizedPerson {PersonDto|null} - object holding data of selected authorized person
 * @param representativeCluid {string|null} - cluid of the selected representative, can be null
 * @returns {{subject: {cluid: string, partyType: 'FOP' | 'PO' | 'FO'}, representative: {cluid: string}}}
 */
const createActor = ({client, clientType, authorizedPerson, representativeCluid}) => {

    /**
     * @type {'FOP'|'PO'|'FO'}
     */
    let selectedClientPartyType;

    if (clientType === 'PERSON') {
        selectedClientPartyType = 'FO';
    } else if (clientType === 'COMPANY') {
        if (client.fop) {
            selectedClientPartyType = 'FOP';
        } else {
            selectedClientPartyType = 'PO';
        }
    }

    const selectedRepresentativeCluid = !!authorizedPerson ? authorizedPerson.cluid : representativeCluid;

    return {
        subject: !!client ? ({
            cluid: client.cluid,
            partyType: selectedClientPartyType
        }) : null,
        representative: !!selectedRepresentativeCluid ? ({
            cluid: selectedRepresentativeCluid
        }) : (clientType === 'PERSON') ? ({ cluid: client?.cluid }) : null,
    }
}

export const ActorUtils = {
    createActor
};