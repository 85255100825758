export const isPerson = (client) => {
    if (client) {
        return client.personalId;
    }
    return false;
};

export const buildEvent = (type, getState, entities, cidla) => {
    let selected = getState().casetype.selected;

    return {
        cidla: cidla,
        type: type,
        session: getState().user.session,
        selectedCase: selected ? {caseType: selected.caseType, startable: selected.ready} : null,
        filterText: getState().casetype.searchText,
        filterHashtags: getState().casetype.selectedTags,
        filteredCases: getState().casetype.filteredList?.map(c => c.caseType),
        searchText: getState().search?.query,
        searchEntities: entities?.map(e => e.ref),
        searchCluid: getState().client?.data?.cluid,
        searchRepresentatives: getState().client?.selectedRepresentatives,
        searchCgpId: getState().product?.data?.cgpId
    };
};

export const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
};