class Resource {

    constructor() {
        if (window.location.host.startsWith("smart")) {
            this.baseUrl = window.location.origin;
        } else {
            this.baseUrl = process.env.REACT_APP_BE_URL;
        }
        this.webApiUrl = process.env.REACT_APP_WEBAPI_URL;
    }

    getSesApi() {
        return (this.webApiUrl) ? this.webApiUrl : (this.baseUrl + "/ses/api");
    }

    checkStatus(response) {
        if(response.status >= 200 && response.status < 300) {
            return response;
        } else {
            return Promise.reject(response);
        }
    }

    getJsonBody(response) {
        if(response.status === 204){
            return Promise.resolve();
        }

        return response.json();
    }

}

export default new Resource();