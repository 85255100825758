import React, {useState} from 'react'
import icons from '../../assets/george-icons-complete.svg';
import PopoverBody from "reactstrap/es/PopoverBody";
import Popover from "reactstrap/es/Popover";

const FontGeorgeIcon = (props) => {

    const [visible, setVisible] = useState(false);

    const getClasses = () => {
        let classes = [];
        classes.push(props.className ? props.className : '');
        classes.push('d-inline-block');
        return classes.join(" ");
    };

    const toggle = (e) => {
        e.stopPropagation();
        setVisible(!visible)
    };

    return (
        <div id={props.id} className={getClasses()} title={props.title}
             onMouseEnter={props.popover ? (e) => toggle(e) : null}
             onMouseLeave={props.popover ? (e) => toggle(e) : null}>
            {props.id ?
            <Popover placement="bottom" isOpen={visible} target={props.id} toggle={toggle}>
                <PopoverBody>
                    {props.popover}
                </PopoverBody>
            </Popover>:null}
            <svg {...props}>
                <use xlinkHref={`${icons}#ico-${props.icon}`}/>
            </svg>
        </div>
    )
};

export default FontGeorgeIcon;