import {
    APPLY_CLIENT_TO_RELATION,
    CLIENT_PRODUCTS_CLEARED,
    PRODUCT_RELATIONS_LOADING,
    PRODUCT_DETAIL_LOADING,
    PRODUCT_TOGGLE_ACTIVE,
    PRODUCT_SELECTED,
    PRODUCT_CLEARED,
    PRODUCT_FAILED,
    PRODUCT_CODE,
    PRODUCT_RELATIONS_LOADING_FAILED,
    CLIENT_PRODUCTS_PAGE_LOADED,
    ACTIVE_PRODUCTS_LOADING,
    HISTORY_PRODUCTS_LOADING,
    CARD_PRODUCTS_LOADING,
    ACTIVE_PRODUCTS_LOADING_FAILED,
    HISTORY_PRODUCTS_LOADING_FAILED,
    CARD_PRODUCTS_LOADING_FAILED,
    PRODUCT_LOADING_TYPE,
    CLEAR_CARDS, RESET_PRODUCTS
} from '../core/consts';
import {getCodebookValue} from "../core/codebook";

const initialState = {
    activeProductsLoading: false, activeProductsLoadingFailed: false,
    historyProductsLoading: false, historyProductsLoadingFailed: false,
    cardProductsLoading: false, cardProductsLoadingFailed: false,
    relationsLoading: false, relationsFailed: false, failed: false, data: undefined, categories: [], notActiveCategory: [], products: {}, active: true};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
    case PRODUCT_DETAIL_LOADING:
        return {...state, loading: true}
    case PRODUCT_RELATIONS_LOADING:
        return {...state, relationsLoading: true, relationsFailed: false};
    case PRODUCT_RELATIONS_LOADING_FAILED:
        return {...state, relationsLoading: false, relationsFailed: true};
    case CLIENT_PRODUCTS_PAGE_LOADED:
        let categories = state.categories;
        let notActiveCategory = state.notActiveCategory;
        let now = new Date().getTime();

        action.products.forEach(p => {
            if(action.backendProductDictionary){
                p.backendProductName = getCodebookValue(action.backendProductDictionary, p.backendProductId)
            }
            p.productLoadingType = action.productLoadingType
        });

        action.products.filter(product => (!product.closeDate || product.closeDate>now)).filter(product => product.financialProductId).forEach(product => {
            let category = getCategory(categories, product.financialProductId);
            addToCategory(category, product);
        });

        action.products.filter(product => (product.closeDate && product.closeDate<=now)).filter(product => product.financialProductId).forEach(product => {
            notActiveCategory.push(product);
        });

        notActiveCategory.sort((a, b) => PRODUCT_CODE[a.financialProductId]?.rank - PRODUCT_CODE[b.financialProductId]?.rank);
        return {...state, data: undefined, categories, notActiveCategory};

    case RESET_PRODUCTS:
        return {...state, data: undefined, categories: [], notActiveCategory: []};

    case ACTIVE_PRODUCTS_LOADING:
        return {...state,
            activeProductsLoading: action.loading, activeProductsLoadingFailed: false};
    case ACTIVE_PRODUCTS_LOADING_FAILED:
        return {...state,
            activeProductsLoading: false, activeProductsLoadingFailed: true};
    case HISTORY_PRODUCTS_LOADING:
        return {...state,
            historyProductsLoading: action.loading, historyProductsLoadingFailed: false};
    case HISTORY_PRODUCTS_LOADING_FAILED:
        return {...state,
            historyProductsLoading: false, historyProductsLoadingFailed: true};
    case CARD_PRODUCTS_LOADING:
        return {...state,
            cardProductsLoading: action.loading, cardProductsLoadingFailed: false};
    case CLEAR_CARDS:
            return {...state, categories: clearCategories(state.categories, PRODUCT_LOADING_TYPE.CARD)};
    case CARD_PRODUCTS_LOADING_FAILED:
        return {...state, cardProductsLoading: false, cardProductsLoadingFailed: true};
    case CLIENT_PRODUCTS_CLEARED:
        return {...state, list: undefined, categories: undefined, notActiveCategory:undefined}
    case PRODUCT_TOGGLE_ACTIVE:
        return {...state, active:!state.active }
    case PRODUCT_SELECTED:
        let data = action.data;
        data.timestamp = new Date();
        return {...state, data: data, loading: false}
    case PRODUCT_CLEARED:
        return {...state, data: null}
    case PRODUCT_FAILED:
        return {...state, failed: true, loading: false};
    case APPLY_CLIENT_TO_RELATION:
        let relations = state?.data?.relations;
        if (relations) {
            relations = relations.map(r => ({...r, client: action.personsInRelations.find(p => r.cluid===p.cluid)}));
        }
        return {...state, data: {...state.data, relations}, relationsLoading:false}
    default:
        return state;
    }
};

const clearCategories = (categories, productLoadingType) => {
    return [...categories
        .map(cat => {
            return {...cat, list: [...cat.list.filter(c => c.productLoadingType !== productLoadingType)]}
        })
        .filter(cat => cat.list.length > 0)
    ];
};

const getCategory = (categories, financialProductId) => {
    let category = categories.find(category => category.id === financialProductId);
    if (!category) {
        category = {id : financialProductId, list: [] };
        categories.push(category);
    }
    return category;
};

const addRelation =(productInstance, relatedProduct) =>{
    productInstance.partyProdInstanceRoleTypes.push(relatedProduct.partyProdInstanceRoleType);

    let partyType = null;
    if(relatedProduct.clientDetail?.person){
        partyType = 'FO';
    }
    else if(relatedProduct.clientDetail?.organization){
        partyType = 'PO';
    }

    if(relatedProduct?.client?.cluid){
        productInstance.relations.push({
            cluid: relatedProduct?.client?.cluid,
            roleType: relatedProduct.partyProdInstanceRoleType,
            partyType: partyType
        })
    }
};

const addToCategory = (category, product) => {
    let productInstance = category.list.find(p => p.cgpId === product.cgpId);
    if(productInstance){
        addRelation(productInstance, product)
    }
    else{
        category.list.push(product);
    }
};

export default productReducer;
