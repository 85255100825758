import React, {useState, useEffect} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Alert  from "reactstrap/es/Alert";
import {useDispatch, useSelector} from "react-redux";
import Representative from "./representative";
import {setRepresentativeId} from "../../core/actions";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";

const RepresentativeList = () => {
    const clientType = useSelector(state => state?.client?.clientType);
    //TODO CD-54127 - přidání více jednatelů
    //const selectedRepresentatives = useSelector(state => state?.client?.selectedRepresentatives ? state?.client?.selectedRepresentatives : []);
    const sel = useSelector(state => state?.client?.selectedRepresentatives[0]);
    const representativeData = useSelector(state => state?.client?.representatives);
    const loading = useSelector(state => state?.client?.representativesLoading);
    const dispatch = useDispatch();

    const [hoverState, setHoverState] = useState("");

    // useEffect(() => {
    //     if(representativeData !== undefined && representativeData.length === 1) {
    //         const client = representativeData[0]
    //         setRepresentative(client?.customerId);
    //     }
    // }, [representativeData, setRepresentative])

    const setRepresentative = (id) => {
        dispatch(setRepresentativeId(id));
    }

    const representativeContent = () => (
        representativeData.map((representative, index) =>
            <Col md={12} key={index}>
                <Representative representative={representative} hoverState={hoverState} setHoverState={setHoverState} selectedItem={sel} setRepresentative={setRepresentative} />
            </Col>)
    )

    return (
        <React.Fragment>
            {clientType === "COMPANY" &&
                <Row className="mb-3 category">
                <Col md={12}>
                    <h5 className="mb-1 d-flex align-items-center">
                        <FontGeorgeIcon className="font-george-icon" icon="group-xl" height={30} width={30}/>
                        <span className="ml-1 font-size-13">Jednatelé</span>
                    </h5>
                </Col>
                {
                    loading ?
                        <Col md={12} className="my-3">Načítám data...</Col> :
                        (representativeData === undefined || representativeData.length < 1 ?
                                <Col md={12} className="my-3"><Alert color="danger">Nenalezeni žádní jednatelé</Alert></Col> :
                                representativeContent()
                        )
                }
            </Row>}
        </React.Fragment>
    );
};

export default RepresentativeList;