import React from "react";
import ModalBody from "reactstrap/es/ModalBody";
import Modal from "reactstrap/es/Modal";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Button from "reactstrap/es/Button";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";

// Modal displayed for clients incoming to starter from CS HUB
// AC-69694
const TabletModal = ({ isOpen, onExit }) => {
    return (
        <Modal isOpen={isOpen} onExit={onExit} toggle={onExit} size="xl" centered="true" fullscreen="xl" className="tablet-modal">
            <ModalBody>
                <div>
                    <Row>
                        <Col className="text-center">
                            <FontGeorgeIcon icon={'sg-furniture'} className="font-george-icon" height={80} width={80} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center"><h2>Pohodlně se usaďte</h2></Col>
                    </Row>
                    <Row>
                        <Col className="text-center"><h5>Bankéř si nyní vezme tablet a bude chvíli pracovat sám</h5></Col>
                    </Row>
                    <Row className="continue-footer">
                        <Button onClick={onExit} size="lg" className="float-left bg-primary">
                            Pokračovat
                        </Button>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default TabletModal;