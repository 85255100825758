import {
    TABLET_USER,
    USER_LOADING,
    USER_LOADED,
    USER_LOADING_FAILED, PREFER_GTI, TABLET_USER_CONFIRMED
} from "../core/consts";


const initialState = {
    identity: null,
    loading: false,
    error: false,
    session: null,
    fromTablet: false,
    fromTabletConfirmed: false,
    preferGti: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {...state, loading: true};
        case USER_LOADED:
            return {...state, identity: action.identity, loading: false, error: false, session: action.session};
        case USER_LOADING_FAILED:
            return {...state, loading: false, error: true};
        case TABLET_USER: {
            return {...state, fromTablet: true};
        }
        case TABLET_USER_CONFIRMED: {
            return {...state, fromTabletConfirmed: true};
        }
        case PREFER_GTI: {
            return {...state, preferGti: !state.preferGti};
        }
        default:
            return state;
    }
};

export default userReducer;
