import Table from "reactstrap/es/Table";
import {resolveMissingInfo} from "../../core/helper/visualHelper";
import CaseInfoTableItem from "./caseInfoTableItem";
import React from 'react';


const CaseInfoTable = ({onlyMissing, caseInfos, className, showFilters}) => {

    return (
        <Table borderless striped bordered className={className}>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>CaseType</th>
                    <th>Chybějící</th>
                    <th>Překlad</th>
                    <th>Categorization Expression</th>
                    <th>Visibility Expression</th>
                </tr>
            </thead>
            <tbody>
                {caseInfos?.filter(ci => onlyMissing ? resolveMissingInfo(ci).length > 0 : true).map(ci =>
                    <CaseInfoTableItem caseInfo={ci} key={ci.caseType} showFilters={showFilters}/>
                )}
            </tbody>
        </Table>
    )

}

export default CaseInfoTable;