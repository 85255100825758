import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {caseTypeSearchTextChanged, clearCaseTypesFilter, scrollOnTop, setCaseTypesFilter} from "../../core/actions";
import {fireEvent} from "../../core/actions/eventActions";
import {FILTERS, STARTER_FILTER_HASHTAG_EVENT, STARTER_FILTER_INPUT_EVENT} from "../../core/consts";
import {Button} from "reactstrap";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import StartInput from "../form/startInput";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";

export const SearchInput = () => {
    const dispatch = useDispatch();

    const selectedTags = useSelector(state => state.casetype?.selectedTags);
    const searchText = useSelector(state => state.casetype?.searchText);

    const [inputRef, setInputRef] = useState(null);
    const [suggestionsDisplayed, setSuggestionsDisplayed] = useState(false);

    const handleSearchTextChanged = (e) => {
        dispatch(caseTypeSearchTextChanged(e.target.value));
        scrollOnTop();
    };

    const handleClicked = (key) => {
        dispatch(setCaseTypesFilter(key));
        scrollOnTop();
        dispatch(fireEvent(STARTER_FILTER_HASHTAG_EVENT));
        if (inputRef) {
            inputRef.focus();
        }
    };

    const handleSuggestionsClear = (e) => {
        e.stopPropagation();
        scrollOnTop();
        dispatch(caseTypeSearchTextChanged(''));
        dispatch(clearCaseTypesFilter())
    };

    const handleShowReset = (selectedTags) => {
        return (selectedTags && selectedTags.length > 0) || searchText;
    };

    const isActive = (filterKey) => {
        return selectedTags.some(st => st === filterKey);
    };

    const handleClose = () => {
        setSuggestionsDisplayed(false)
    };

    const handleOpen = () => {
        if (!suggestionsDisplayed) {
            setSuggestionsDisplayed(true)
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (suggestionsDisplayed) {
                handleClose();
            } else {
                handleOpen();
            }
        }
    };

    const handleToggle = () => {
        if (suggestionsDisplayed) {
            handleClose();
        } else {
            handleOpen();
        }
    };

    return (
        <>
            <div className={"overlay " + (suggestionsDisplayed ? 'd-block' : 'd-none')} onClick={handleClose}/>
            <div className="d-block position-relative searchinput" onKeyDown={handleKeyDown}>
                <div className="d-flex position-relative flex-wrap searchinput-input-wrapper" onClick={handleOpen}>
                    {selectedTags.map((tag, idx) =>
                        <Button
                            key={tag}
                            active={true}
                            className={"my-2 mr-1 " + (idx === 0 ? 'ml-3' : 'ml-1')}
                            color="hashtag"
                            onMouseDown={() => handleClicked(tag)}
                            size="sm">
                            {FILTERS[tag].label}
                            <FontGeorgeIcon className="ml-1" icon="error" width={15} height={15}/>
                        </Button>
                    )}
                    <StartInput setRef={setInputRef}
                                value={searchText}
                                icon="search"
                                id="tags-search-input"
                                clearable={true}
                                delay={500}
                                onDelay={() => dispatch(fireEvent(STARTER_FILTER_INPUT_EVENT))}
                                onIconClick={handleToggle}
                                placeholder={!handleShowReset(selectedTags) ? 'Filtrovat požadavky' : ''}
                                onClear={handleSuggestionsClear}
                                showReset={() => handleShowReset(selectedTags)}
                                onChange={handleSearchTextChanged}/>
                </div>
                <div className={`p-2 pt-3 searchinput-suggestions ${suggestionsDisplayed ? 'd-block' : 'd-none'}`}>
                    <Row>
                        <Col>
                            <h4 className="mx-2">
                                Kategorie požadavků
                            </h4>
                        </Col>
                        <Col className="col-md-auto">
                            <FontGeorgeIcon icon="caret-up" width={30} height={30}
                                            className="toggle float-right selectable" onClick={handleClose}/>
                        </Col>
                    </Row>

                    {Object.keys(FILTERS).map((filterKey) =>
                        <Button
                            key={filterKey}
                            active={isActive(filterKey)}
                            onMouseDown={() => handleClicked(filterKey)}
                            className={"m-2 " + (isActive(filterKey) ? "icon-padding" : "text-center px-4")}
                            title={FILTERS[filterKey].label}
                            outline color="hashtag"
                            size="md">{FILTERS[filterKey].label}
                            {isActive(filterKey) ?
                                <FontGeorgeIcon className="mx-1" icon="error" width={15} height={15}/> : null}
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
};