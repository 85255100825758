import React from "react";
import Loading from "./loading";

const LoadingLayer = (props) => {

	return (
        <div className={props.full ? "loading-layer full" : "loading-layer"}>
            { props.isLoading ?
                <div>
                    <div className="background"></div>

                    <div className="symbols">
                        <Loading/>
                        <br/>
                        {props.text}
                    </div>
                </div>
                : null
            }
            {props.children}
        </div>
	)
}

export default LoadingLayer;