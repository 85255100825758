export const resolveMissingInfo = (caseInfo) => {
    if(!caseInfo){
        return [];
    }

    let missing = [];
    if (!caseInfo.icon) {
        missing.push('ikonka')
    }
    if (!caseInfo.categorizationLabel) {
        missing.push('popis')
    }
    if (!caseInfo.explanation) {
        missing.push('vysvětlení kategorizační expression')
    }
    if (!caseInfo.categorizationExpression) {
        missing.push('kategorizační expression.')
    }
    if(!caseInfo.tags || caseInfo.tags.length === 0){
        missing.push('tagy')
    }

    return missing;
};