import React from 'react';
import {Row, Col, Card} from 'reactstrap';
import TaskFormVertical from "../form/taskFormVertical";
import CardBody from "reactstrap/es/CardBody";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import {RELATION_OWNER} from "../../core/consts";
import {useSelector} from "react-redux";
import {isPerson} from "../../core/helper/actionHelper";
import ClientAlerts from "./clientAlerts";

const Client = ({entity, client, relation, compressed, className, onClick, onRemove}) => {
    const selectedClient = useSelector(state => state?.client?.data);
    const fromTablet = useSelector(state => state?.user?.fromTablet);

    const getClasses = () => {
        let classes = [];
        classes.push(className ? className : '');
        classes.push(onClick ? 'selectable' : '');
        classes.push(compressed ? 'compressed' : null);
        classes.push('entity');
        classes.push('client');
        return classes.join(" ");
    };

    const handleOnClick = () => {
        if (entity) {
            onClick(entity)
        } else if (client) {
            onClick(client)
        }
    };

    const resolveLabel = () => {
        if (relation) {
            return relation.roleTypeName;
        }

        return "Klient"
    };

    return (
        <Card onClick={onClick ? handleOnClick : null} className={getClasses()}>
            <CardBody className="py-1">
                {compressed && entity ?
                    <Row>
                        <Col md="auto" className="my-auto text-center smart-icon">
                            <FontGeorgeIcon icon="user-xl" height={40} width={40}/>
                        </Col>
                        <Col className="pl-0">
                            <TaskFormVertical label="Klient" id="client-name" className="pl-0">
                                <span className="font-weight-bolder font-size-12"> {entity.label}</span>
                            </TaskFormVertical>
                        </Col>
                    </Row>
                    : null}
                {compressed && client ?
                    <Row className={client.cluid === selectedClient?.cluid ? 'selected' : ''}>
                        {client.cluid === selectedClient?.cluid ?
                            <div className="selected-icon"/> : null}

                        <Col md="auto" className="my-auto text-center smart-icon">
                            <FontGeorgeIcon icon={relation && relation.roleType === RELATION_OWNER ? 'user' : 'user-xl'}
                                            height={40} width={40}/>
                        </Col>
                        <Col className="pl-0">
                            <TaskFormVertical label={resolveLabel()} id="client-name" className="pl-0">
                                <span
                                    className="font-weight-bolder font-size-12"> {isPerson(client) ? client.fullName : client.name}</span>
                            </TaskFormVertical>
                        </Col>
                    </Row>
                    : null}

                {!compressed && client ?
                    <>
                        <Row>
                            <Col className="font-weight-bolder pl-1">
                                <TaskFormVertical id="name" className="font-weight-bold font-size-15">
                                    <span className="name">{isPerson(client) ? client.fullName : client.name}</span>
                                    <ClientAlerts clientAlerts={client.clientAlerts} activeOnly={true} placement={'top'} id={'client-alerts'} className={'ml-2'} cluid={client.cluid}/>
                                </TaskFormVertical>
                            </Col>
                            <Col md="auto" className="py-1 px-2">
                                {!fromTablet &&
                                    <FontGeorgeIcon title="Zadat jiného klienta" icon="error-xl" className="selectable"
                                                    onClick={(e) => onRemove(e)} width={30} height={30}/>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="px-0">
                                <TaskFormVertical id="birth-number"
                                                  icon={isPerson(client) ? "calendar-xl" : 'invoice-digital-xl'}
                                                  className="font-weight-bolder">
                                    <span>{isPerson(client) ? client.personalId : client.registrationNumber}</span>
                                </TaskFormVertical>
                            </Col>
                            <Col md={6} className="px-0">
                                <TaskFormVertical id="phone" icon="phone-hotline-xl" className="font-weight-bolder">
                                    {client.primaryPhoneNumber ?
                                        <span>{client.primaryPhoneNumber}</span>
                                        :
                                        <span className="text-muted">Není k dispozici</span>
                                    }
                                </TaskFormVertical>
                            </Col>
                            <Col md={12} lg={12} xl={12} className="pl-0">
                                <TaskFormVertical id="email" icon="e-mail-xl" className="font-weight-bolder">
                                    {client.primaryEmail ?
                                        <span>{client.primaryEmail}</span>
                                        :
                                        <span className="text-muted">Není k dispozici</span>
                                    }
                                </TaskFormVertical>
                            </Col>
                            <Col md={12} lg={12} xl={12} className="pl-0">
                                <TaskFormVertical id="adress" icon="location-xl" className="font-weight-bolder">
                                    <span>{client.address}</span>
                                </TaskFormVertical>
                            </Col>
                            <Col md={12} className="pl-0">
                                <TaskFormVertical id="cluid" icon="s-id-app" className="font-weight-bolder">
                                    <span>{client.cluid}</span>
                                </TaskFormVertical>
                            </Col>
                        </Row>
                    </>
                    : null}
            </CardBody>
        </Card>
    )
};

export default Client;