import React from "react";

const DateTime = ({millis, format}) => {

    const timestampToDate = () => {
        let date = new Date(millis);
        let result = format;

        result = replaceDaysInString(result, date.getDate());
        result = replaceMonthsInString(result, date.getMonth() + 1);
        result = replaceYearsInString(result, date.getFullYear());
        result = replaceHoursInString(result, date.getHours());
        result = replaceMinutesInString(result, date.getMinutes());
        result = replaceSecondsInString(result, date.getSeconds());

        return result;
    };

    const replaceDaysInString = (string, day) => {
        if (string.indexOf("dd") >= 0) {
            let days = day < 10 ? '0' + day : day;
            return string.replace("dd", days);
        } else if (string.indexOf("d") > 0) {
            return string.replace("dd", day);
        }

        return string;
    };

    const replaceMonthsInString = (string, month) => {
        if (string.indexOf("MM") >= 0) {
            let months = month < 10 ? '0' + month : month;
            return string.replace("MM", months);
        } else if (string.indexOf("M") > 0) {
            return string.replace("M", month);
        }
        return string;
    };

    const replaceYearsInString = (string, year) => {
        if (string.indexOf("yyyy") >= 0) {
            return string.replace("yyyy", year);
        } else if (string.indexOf("yy") > 0) {
            return string.replace("yy", year.toString().substring(2));
        }
        return string;
    };

    const replaceHoursInString = (string, hour) => {
        if (string.indexOf("HH") >= 0) {
            let hours = hour < 10 ? '0' + hour : hour;
            return string.replace("HH", hours);
        }
        return string;
    };

    const replaceMinutesInString = (string, minute) => {
        if (string.indexOf("mm") >= 0) {
            let minutes = minute < 10 ? '0' + minute : minute;
            return string.replace("mm", minutes);
        }
        return string;
    };

    const replaceSecondsInString = (string, second) => {
        if (string.indexOf("ss") >= 0) {
            let seconds = second < 10 ? '0' + second : second;
            return string.replace("ss", seconds);
        }
        return string;
    };

    return (
        <>
        {millis ?
            <span>{timestampToDate(millis, format)}</span>
        : null}
        </>
    )
};

export default DateTime