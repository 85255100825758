import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Container, Row, Col} from 'reactstrap';
import {
    casePollSuccessful,
    casePollTimedOut,
    openGtiLink,
    pollForSyncCase,
    startCase
} from '../core/actions';
import CaseFlipCard from './cases/caseFlipCard';
import LoadingLayer from "./form/loadinglayer";
import EmptyInfo from "./feedback/EmptyInfo";
import {SmartPolling} from "@csas-smart/smart-ui-comps";
import {fireShowAlert} from "../core/actions/alertActions";
import {ALERT_DANGER} from "../core/consts";
import {isError} from "../reducers/utils/dataBuilder";

const CaseTypes = () => {
    const dispatch = useDispatch();
    const list = useSelector(state => state.casetype?.filteredList);
    const selected = useSelector(state => state.casetype?.selected);
    const loading = useSelector(state => state.casetype?.loading);
    const expressionLoading = useSelector(state => state.casetype?.expressionLoading);
    const loaded = useSelector(state => state.casetype?.loaded);
    const gtiLink = useSelector(state => state.casetype.selected?.gtiLink);
    const caseIndicatedForPolling = useSelector(state => state.casetype.caseAsyncIndicatorResponse?.sync);
    const cidlaIndicatedForPolling = useSelector(state => state.casetype.caseAsyncIndicatorResponse?.cidla);
    const caseAsync = useSelector(state => state.casetype.caseAsync);
    const fromTablet = useSelector(state => state.user?.fromTablet);
    const [style, setStyle] = useState(null);
    const header = document.getElementById('header');
    const filter = document.getElementById("filterSection");


    useEffect(() => {
        if(header && filter){
            let totalHeight = header.offsetHeight + filter.offsetHeight;
            setStyle({
                height: "calc(100vh - " + totalHeight + 'px)',
                overflowY : 'auto'
            })
        }
    }, [header, filter, list]);

    const handleStartClicked = (e) => {
        e.stopPropagation();

        if (!selected) {
            return;
        }

        if(gtiLink && selected.startBti){
            dispatch(openGtiLink(gtiLink));
        } else {
            // pro startBti=true divné chování: volá se ses/api/case (GtiLinkService.getNgCaseLink, což volá ngruntime: /definitions/productId=XXX/casetypes - to asi nechceme podporovat?)
            dispatch(startCase());
        }
    };

    const isEmpty = () => {
        return (!list || list.length === 0);
    };

    const onPollingTimout = () => {
        dispatch(casePollTimedOut())
        dispatch(fireShowAlert("Založení případu selhalo", ALERT_DANGER))
    }

    const isPollingSuccessful = () => {
        return !!caseAsync?.data?.applicationUrl
    }

    const onPollingSuccesful = () => {
        dispatch(casePollSuccessful())
        window.open(caseAsync?.data?.applicationUrl, fromTablet ? '_self' : '_blank')
    }

    return (
        <React.Fragment>
            <LoadingLayer isLoading={loading || expressionLoading}>
                <Row id="caseTypesSection" className="casetypes" style={style}>
                    <Col md={12}>
                        {!isEmpty() ?
                        <Container fluid>
                            <Row className="py-2">
                                {list.map(ct => <Col className="px-0 mb-4" xl={3} lg={4} md={4} sm={4} xs={6}
                                                     key={ct.caseType}><CaseFlipCard caseType={ct}
                                                                                     selected={selected}
                                                                                     gtiLink={gtiLink}
                                                                                     onStart={handleStartClicked}/></Col>)}
                            </Row>
                        </Container> : null}

                        {isEmpty() && loaded && !loading ?
                        <Row className="mt-3">
                            <Col className="col-md-4 mx-auto">
                                <EmptyInfo icon={"folder-delete-xl"} iconWidth={100} text="Nenašli jsme žádné požadavky"/>
                            </Col>
                        </Row> : null}

                        <SmartPolling pollCondition={caseIndicatedForPolling}
                                      key={cidlaIndicatedForPolling}
                                      onPoll={() => dispatch(pollForSyncCase(cidlaIndicatedForPolling))}
                                      successPollCondition={isPollingSuccessful()}
                                      onSuccessPoll={() => onPollingSuccesful()}
                                      failurePollCondition={isError(caseAsync)}
                                      onFailurePoll={() => dispatch(fireShowAlert("Založení případu selhalo", ALERT_DANGER))}
                                      timeoutInMillis={30000}
                                      intervalInMillis={1000}
                                      onTimeout={() => onPollingTimout()}/>
                    </Col>
                </Row>
            </LoadingLayer>
        </React.Fragment>
    )

};

export default CaseTypes;