import React, {useEffect, useState} from "react";
import {resolveMissingInfo} from "../../core/helper/visualHelper";
import {faDotCircle} from "@fortawesome/free-solid-svg-icons/faDotCircle";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CaseInfoTableItem = ({caseInfo, showFilters}) => {

    const [missing, setMissing] = useState([]);

    useEffect(() => {
        setMissing(resolveMissingInfo(caseInfo))
    }, [caseInfo]);

    const hasMissing = () => {
        return missing && missing.length > 0;
    };

    return (
        <>
            <tr>
                <td>
                    <FontAwesomeIcon icon={hasMissing() ? faDotCircle : faCheckCircle}
                                     className={hasMissing() ? 'text-danger' : 'text-success'}/>
                </td>
                <td>{caseInfo.caseType}{showFilters}</td>
                <td>{missing.join(", ")}</td>
                <td>{caseInfo?.explanation}</td>
                <td style={{wordBreak: 'break-all'}}>{caseInfo?.categorizationExpression}</td>
                <td style={{wordBreak: 'break-all'}}>{`${caseInfo?.visibilityExpression}`}</td>
            </tr>
            {showFilters ?
                <tr>
                    <td colSpan={5}>{caseInfo?.filter?.join(";")}</td>
                </tr> : null}
        </>
    )

};

export default CaseInfoTableItem;