import {CODEBOOK_SET_RESULT} from '../core/consts'

const initialState = {codebooks: {}};

const codebookReducer = (state = initialState, action) => {
    switch (action.type) {
        case CODEBOOK_SET_RESULT:{
            var codebooks = state.codebooks;
            codebooks[action.codebookName] = action.codebook;
            return {...state, codebooks: codebooks}
        }
        default: {
            return state;
        }

    }
};

export default codebookReducer;