import {VIEWPORT_UPDATED} from "../core/consts";

const initialState = { width: null, height: null};

const viewportReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEWPORT_UPDATED:
            return {...state, width: action.width, height: action.height};
        default:
            return state;
    }
};

export default viewportReducer;
