import {
    ALERT_SUCCESS,
    REPORT_INCIDENT_CLEARED,
    REPORT_INCIDENT_FAILED,
    REPORT_INCIDENT_FINISHED,
    REPORT_INCIDENT_STARTED
} from "../consts";
import Resource from "../serverresource";
import {fireShowAlert} from "./alertActions";

export const reportIncidentStarted = () => (
    {type: REPORT_INCIDENT_STARTED}
)

export const reportIncidentFailed = (err) => (
    {type: REPORT_INCIDENT_FAILED, err}
)

export const reportIncidentFinished = () => (
    {type: REPORT_INCIDENT_FINISHED}
)

export const reportIncidentCleared = () => (
    {type: REPORT_INCIDENT_CLEARED}
)

export const reportIncident = (data) => {
    return (dispatch) => {

        dispatch(reportIncidentStarted());

        let formData = new FormData();
        if(data.files) {
            data.files.forEach(f => {
                formData.append('files', f);
            });
        }
        formData.append('subject', data.subject);
        formData.append('description', data.description);
        formData.append('priority', data.priority);
        formData.append('system', data.system);

        return fetch(Resource.getSesApi() + "/incident", {
            method: "POST",
            body: formData
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                dispatch(reportIncidentFinished());
                dispatch(fireShowAlert("Incident založen, č. incidentu: " + data.number, ALERT_SUCCESS, 20000));
                return Promise.resolve(data);
            })
            .catch(err => {
                dispatch(reportIncidentFailed(err.status + ' - ' + err.statusText));
                return Promise.reject(err);
            });
    }
};