import React from "react";
import CustomInput from "reactstrap/es/CustomInput";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";

/**
 * StartSingleCheckbox
 */
const StartSingleCheckbox = ({value, onChange, id, defaultChecked, label, className, disabled, popover}) => {
    return (
        <React.Fragment>
            <CustomInput id={id}
                         disabled={disabled}
                         type="checkbox"
                         checked={value}
                         className={className}
                         label={label}
                         defaultChecked={defaultChecked}
                         onChange={onChange}/>
            {popover ?
            <FontGeorgeIcon icon={"help"} id={id + "-hint"} popover={popover} width={23} height={23} className="ml-1 d-inline-block hint"/> : null}
        </React.Fragment>
    );
};

export default StartSingleCheckbox;