import {
    CASETYPES_LOADED,
    CASETYPE_SELECTED,
    CASETYPE_SEARCH_TEXT_CHANGED,
    CASETYPE_SET_FILTER,
    CASETYPES_FAILED,
    CASETYPES_BY_EXPRESSION_LOADED,
    CASETYPES_LOADING,
    CASETYPE_STARTING,
    CASETYPE_STARTED,
    CASETYPE_FREQUENCIES_LOADED,
    CASETYPE_ONLY_FREQUENT_CHANGED,
    FILTERS,
    CASETYPE_FAILED,
    CASETYPE_CLEAR_FILTER,
    CASETYPES_BY_EXPRESSION_FAILED,
    CASETYPES_BY_EXPRESSION_LOADING,
    CASETYPE_GTI_LINK_LOADED,
    CASETYPE_GTI_LINK_LOADING_FAILED,
    CASETYPE_GTI_LINK_LOADING,
    CASE_POLLING_IN_PROGRESS,
    CASE_POLLED,
    CASE_POLL_FAILED,
    STARTED_ASYNC_CASE,
    CASE_POLL_TIMEDOUT, CASE_POLL_SUCCESFUL
} from '../core/consts';
import { filterIt } from '../core/utils';
import {dataBuild, dataLoaded, dataLoading, dataLoadingFailed} from "./utils/dataBuilder";

// Temporary flag enabling filtering case types by visibility expression - could be replaced by state value from UI
const FILTER_BY_VISIBILITY_EXPR_ENABLED = true;

const initialState = {
    list : [],
    selected : null,
    selectedTags: [],
    onlyComplains: false,
    loaded: false,
    loading: false,
    failed: false,
    searchText: "",
    filteredList: [],
    starting: false,
    onlyFrequent: false,
    error: false,
    expressionFailed: false,
    expressionLoading: false,
    caseAsync: dataBuild(null),
    caseAsyncIndicatorResponse: null
};

const setAsReady = (list, expressionList, visibleCaseTypes) => {
    return list.map(item => {
        item = {...item, visibleByExpression: visibleCaseTypes.indexOf(item.caseType) !== -1};
        const expressionItem = expressionList.find(expr => item.caseType === expr.caseType);
        return (item.ready === !!expressionItem) ? item : {...item, ready:!!expressionItem}
    });
}

const setFrequency = (list, frequencies) => {
    return list.map(item => frequencies[item.caseType] ? {...item, frequency: frequencies[item.caseType]}: {...item, frequency: 0});
}

const sortCaseTypes = (list) => {
    let comparator = (a, b) => {
        //[1] ready
        return b.ready - a.ready ||
        //[2] frequency
        b.frequency - a.frequency ||
        //[3] module (it may override ready after clicking on the card, so they are preferred against others
        !!b.module - !!a.module ||
        //[4] label
        a.label.localeCompare(b.label);
    }
    return list.sort(comparator);
}

const applyFilters = (list, selectedTags, searchText, onlyFrequent, visibleByExpression) => {
    let prefiltered = list.filter(item => onlyFrequent ? item.frequency > 0 : true)
        .filter(item => visibleByExpression ? item.visibleByExpression : true);
    let tags = selectedTags.map(st => FILTERS[st].hashtags).reduce((a, b) => [...a, ...b], []);
    tags = [...tags, ...(searchText || "").split(" ").filter(tag => tag !== "")];
    let result = prefiltered;
    tags.forEach(tag => {
        result = filterIt(result, tag);
    });
    return result;
};

const caseTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CASETYPES_LOADED:
            return {...state, list: action.list, loaded: true};
        case CASETYPE_SELECTED:
            return {...state, selected: state.selected && state.selected.caseType === action.selected.caseType ? null : action.selected};
        case CASETYPE_SEARCH_TEXT_CHANGED:
            return {...state, searchText: action.text, filteredList: applyFilters(state.list, state.selectedTags, action.text, state.onlyFrequent, FILTER_BY_VISIBILITY_EXPR_ENABLED)};
        case CASETYPE_ONLY_FREQUENT_CHANGED:
            return {...state, onlyFrequent: action.value, filteredList: applyFilters(state.list, state.selectedTags, state.searchText, action.value, FILTER_BY_VISIBILITY_EXPR_ENABLED)};
        case CASETYPES_BY_EXPRESSION_LOADED:
            let list = setAsReady(state.list, action.list, action.visibleCaseTypes);
            let sorted = sortCaseTypes(list);
            return {
                ...state, list, filteredList: applyFilters(sorted, state.selectedTags, state.searchText, state.onlyFrequent, FILTER_BY_VISIBILITY_EXPR_ENABLED), loading: false, selected: updateSelected(state.selected, list),
                expressionLoading: false, expressionFailed: false
            };
        case CASETYPES_BY_EXPRESSION_FAILED:
            return {...state, expressionLoading: false, expressionFailed: true, loading: false};
        case CASETYPES_BY_EXPRESSION_LOADING:
            return {...state, expressionLoading: true, expressionFailed: false, loading: true};
        case CASETYPE_FREQUENCIES_LOADED:
            let l2 = setFrequency(state.list, action.data);
            return { ...state, list: l2, filteredList: applyFilters(l2, state.selectedTags, state.searchText, state.onlyFrequent, FILTER_BY_VISIBILITY_EXPR_ENABLED) };
        case CASETYPES_LOADING:
            return {...state, loading: true, failed: false};
        case CASETYPES_FAILED:
            return {...state, loading:false, failed:true};
        case CASETYPE_SET_FILTER:
            let selectedTags = [];
            if(state.selectedTags.some(st => st === action.selectedTag)){
                selectedTags = [...state.selectedTags.filter(st => st !== action.selectedTag)]
            }else{
                selectedTags = [...state.selectedTags, action.selectedTag]
            }
            return { ...state, selectedTags: selectedTags, filteredList: applyFilters(state.list, selectedTags, state.searchText, state.onlyFrequent, FILTER_BY_VISIBILITY_EXPR_ENABLED) };
        case CASETYPE_CLEAR_FILTER:
            return { ...state, selectedTags: [], filteredList: applyFilters(state.list, [], state.searchText, state.onlyFrequent, FILTER_BY_VISIBILITY_EXPR_ENABLED) };
        case CASETYPE_STARTING:
            return {...state, starting: true, error: false};
        case CASETYPE_STARTED:
            return {...state, starting: false, error: false};
        case CASETYPE_FAILED:
            return {...state, starting: false, error: true};
        case CASETYPE_GTI_LINK_LOADING:
            return {...state, selected: {...state.selected, loading: true, failed: false}};
        case CASETYPE_GTI_LINK_LOADING_FAILED:
                return {...state, selected: {...state.selected, loading: false, failed: true}};
        case CASETYPE_GTI_LINK_LOADED:
            return {...state, selected: {...state.selected, gtiLink: action.gtiLink, loading: false}};
        case CASE_POLLING_IN_PROGRESS:
            return dataLoading(state, 'caseAsync');
        case CASE_POLLED:
            return dataLoaded(state, 'caseAsync', action.response);
        case CASE_POLL_FAILED:
            let newState = dataLoadingFailed(state, 'caseAsync', null, null);
            return {...newState, starting: false};
        case CASE_POLL_TIMEDOUT:
            return {...state, starting: false, caseAsync: dataBuild(null), caseAsyncIndicatorResponse: null};
        case CASE_POLL_SUCCESFUL:
            return {...state, starting: false, caseAsync: dataBuild(null), caseAsyncIndicatorResponse: null};
        case STARTED_ASYNC_CASE:
            return {...state, caseAsyncIndicatorResponse: action.response}
        default:
            return state;
    }
};

const updateSelected = (selected, list) => {
    if (!selected) {
        return selected;
    }

    let updatedByExpression = list.filter(ct => ct.caseType === selected.caseType);
    return updatedByExpression ? updatedByExpression[0] : selected;
};

export default caseTypeReducer;