import { CODEBOOK_SET_RESULT } from './consts';
import Resource from './serverresource';

export const setCodebookResult = (codebookName, codebook) => (
    {type: CODEBOOK_SET_RESULT, codebookName, codebook}
);

export const getCodebook = (codebookName) => {
    return (dispatch, getState) => {
        var codebook = getState().codebook.codebooks[codebookName];
        if (codebook) {
            return Promise.resolve(codebook);
        } else {
            return getCodebooksInternal(codebookName)
                .then(response => {
                    dispatch(setCodebookResult(codebookName, response));
                    return response;
                })
                .catch(error => {
                    console.error("Unable to fetch codebooks " + codebookName, error);
                    throw error;
                });
        }
    };
}

function getCodebooksInternal(codebookName) {
    return fetch(Resource.getSesApi() + "/codebook/" + codebookName)
        .then(response => response.json());
}

export const getCodebookItem = (codebook, codebookId) => {
    return codebook.find(codebookObject => codebookObject.id === codebookId);
}

export const getCodebookValue = (codebook, codebookId) => {
    return getCodebookItem(codebook, codebookId)?.value;
}