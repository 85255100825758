import {
    CLIENT_CLEARED,
    PRODUCT_CLEARED,
    SEARCH_QUERY_ACTION,
    SEARCH_QUERY_ACTION_FAILED,
    SEARCH_QUERY_CHANGED
} from '../core/consts';

const initialState = { query: "", loading: false, data: [], loaded: false, failed: false};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_QUERY_CHANGED:
            return {...state, query: action.text};
        case SEARCH_QUERY_ACTION:
            return {...state, data: action.data, loading: action.loading, loaded: action.loaded, failed: false};
        case SEARCH_QUERY_ACTION_FAILED:
            return {...state, loading: false, loaded: true, failed: true};
        case CLIENT_CLEARED:
            return {...state, loaded: false};
        case PRODUCT_CLEARED:
            return {...state, loaded: false};
        default:
            return state;
    }
};

export default searchReducer;
