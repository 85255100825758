import {PRODUCT_CODE} from "../../core/consts";
import React, {useState, useEffect} from 'react';
import Col from "reactstrap/es/Col";
import Product from "./product";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import Row from "reactstrap/es/Row";

const ProductCategory = ({category, productClicked, productClassName}) => {
    const [hoverState, setHoverState] = useState("");
    const {id, list} = category;

    const getName = (financialProductId) => {
        return PRODUCT_CODE[financialProductId] ? PRODUCT_CODE[financialProductId].name : financialProductId;
    }

    const getIcon = () => {
        return PRODUCT_CODE[id].icon;
    };

    return (<React.Fragment>
        <Row className="mb-3 category">
            <Col md={12}>
                <h5 className="mb-1 d-flex align-items-center">
                    <FontGeorgeIcon className="font-george-icon" icon={getIcon()} height={30} width={30}/>
                    <span className="ml-2 font-size-13">{getName(id)}</span>
                </h5>
            </Col>
            {
                list.map((product, index) =>
                    <Col md={12} key={product.cgpId}>
                        <Product index ={index}
                                 product={product}
                                 compressed={true}
                                 className={`mb-2 ${productClassName} ${hoverState === index && "active-side-card"}`}
                                 setHoverState={setHoverState}
                                 onClick={productClicked}/>
                    </Col>)
            }
        </Row>
    </React.Fragment>)
}

export default ProductCategory; 