import {
    PREFER_GTI,
    STARTER_SELECTION_STARTED,
    TABLET_USER,
    TABLET_USER_CONFIRMED,
    USER_LOADED,
    USER_LOADING,
    USER_LOADING_FAILED,
    USER_LOGOUT
} from "../consts";
import Resource from "../serverresource"
import {generateUUID} from "../helper/actionHelper";
import {fireEvent} from "./eventActions";

export const userLoading = () => (
    {type: USER_LOADING}
);

export const userLoaded = (identity) => (
    {type: USER_LOADED, identity, session: generateUUID()}
);

export const userLoadingFailed = (err) => (
    {type: USER_LOADING_FAILED, err}
);

export const userLogout = () => (
    {type: USER_LOGOUT}
);

export const userFromTablet = () => (
    {type: TABLET_USER}
);

export const userFromTabletConfirmed = () => (
    { type: TABLET_USER_CONFIRMED }
);

export const preferGtiSelected = () => (
    {type: PREFER_GTI}
);

export function fireLogin() {
    return (dispatch) => {
        dispatch(userLoading());
        fetch(Resource.getSesApi() + "/user", {
            method: "POST",
            headers: {"Content-Type": "application/json" }
        }).then(Resource.checkStatus)
          .then(response => response.json())
          .then(user => {
              dispatch(userLoaded(user));
              dispatch(fireEvent(STARTER_SELECTION_STARTED));
          }).catch(err => {
              //error is being handled by blocking dialog on App.js
              dispatch(userLoadingFailed(err));
        });
    };
}

export function fireLogout() {
    return (dispatch) => {
        window.sessionStorage.clear();
        window.location.replace(window.location.href);
        dispatch(userLogout())
    }
}