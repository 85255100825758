import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import Button from "reactstrap/es/Button";
import Modal from "reactstrap/es/Modal";
import React, {useEffect, useState} from "react";
import CaseInfoTable from "../cases/caseInfoTable";
import StartSingleCheckbox from "../form/startSingleCheckbox";
import TaskFormVertical from "../form/taskFormVertical";
import {PieChart} from "react-minimal-pie-chart";
import {useSelector} from "react-redux";
import {resolveMissingInfo} from "../../core/helper/visualHelper";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";

const DebugModal = ({isOpen, onExit}) => {
    const [onlyMissing, setOnlyMissing] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [missingCount, setMissingCount] = useState(0);
    const [completeCount, setCompleteCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    let caseInfos = useSelector(state => state.casetype.list);

    useEffect(() => {
        setTotalCount(caseInfos.length);
        setMissingCount(caseInfos?.filter(ci => resolveMissingInfo(ci).length > 0).length);
        setCompleteCount(caseInfos?.filter(ci => resolveMissingInfo(ci).length === 0).length)
    }, [caseInfos]);

    const handleOnlyMissingChanged = (e) => {
        setOnlyMissing(!onlyMissing);
    };

    const handleShowFiltersChanged = (e) => {
        setShowFilters(!showFilters);
    };

    const data = [
        {title: 'Kompletní požadavky', value: completeCount, color: '#39ca28'},
        {title: 'Požadavky s chybějícími daty', value: missingCount, color: '#C13C37'},
    ];

    return (
        <Modal isOpen={isOpen} onExit={onExit} size="xl" toggle={onExit}>
            {isOpen ?
                <>
                    <ModalHeader toggle={onExit}>
                        Datový přehled
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className="text-center">
                                <PieChart
                                    lineWidth={30}
                                    viewBoxSize={[150,150]}
                                    center={[75, 75]}
                                    style={{height: '200px', width: '200px'}}
                                    data={data}
                                    label={({dataEntry}) => dataEntry.value}
                                    labelStyle={(index) => ({
                                        fill: data[index].color,
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        fontFamily: 'sans-serif',
                                    })}
                                    paddingAngle={5}
                                    labelPosition={105}
                                />
                                <span className="ml-3 font-weight-bold">Celkem: {totalCount} požadavků</span>
                            </Col>
                        </Row>

                        <TaskFormVertical label={"Náhledy"}>
                            <StartSingleCheckbox
                                id="case-debug-only-missing"
                                label="Pouze nekompletní"
                                value={onlyMissing}
                                className="d-inline-block mr-5"
                                onChange={handleOnlyMissingChanged}/>

                            <StartSingleCheckbox
                                id="case-show-filters"
                                label="Ukázat filtry"
                                value={showFilters}
                                className="d-inline-block"
                                onChange={handleShowFiltersChanged}/>
                        </TaskFormVertical>

                        <CaseInfoTable onlyMissing={onlyMissing} caseInfos={caseInfos} className={"mt-2"} showFilters={showFilters}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onExit} size="lg" className="float-left bg-secondary">
                            Zavřít
                        </Button>
                    </ModalFooter>
                </>
                : null}
        </Modal>
    );
}

export default DebugModal;