import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {caseTypeOnlyFrequentChanged, scrollOnTop} from '../core/actions';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";

import TaskFormVertical from "./form/taskFormVertical";
import StartSingleCheckbox from "./form/startSingleCheckbox";
import {SearchInput} from "./searchinput/searchinput";

const Filter = () => {
    const dispatch = useDispatch();
    const onlyFrequent = useSelector(state => state.casetype?.onlyFrequent);

    const handleOnlyFrequentChanged = () => {
        scrollOnTop();
        dispatch(caseTypeOnlyFrequentChanged(!onlyFrequent));
    };

    return (
        <React.Fragment>
            <Row id="filterSection" className="filter-section">
                <Col md={9}>
                    <TaskFormVertical label="Zadejte hledaný výraz" className="pl-0" popover="Vyhledávejte požadavky podle textu a nebo podle kategorií." id="filter-label">
                        <SearchInput/>
                    </TaskFormVertical>
                </Col>
                <Col md={3} className="pt-3">
                    <TaskFormVertical className="pt-4" >
                        <StartSingleCheckbox
                            id="case-form-only-active"
                            className="d-inline-block"
                            label="Moje nejčastější"
                            value={onlyFrequent}
                            popover={"Vámi nejčastěji zadávané požadavky za poslední 3 měsíce"}
                            onChange={handleOnlyFrequentChanged}/>
                    </TaskFormVertical>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Filter;