import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {
    loadProducts,
    productCleared
} from "../../core/actions";
import {useDispatch, useSelector} from "react-redux";
import Product from "./product";

const ProductDetail = ({className}) => {
    const dispatch = useDispatch();
    const selectedClient = useSelector(state => state.client.data);
    const selectedProduct = useSelector(state => state.product.data);

    const handleClearClicked = (e) => {
        e.preventDefault();
        dispatch(productCleared())
    };

    const handleLoadClientProducts = (e) => {
        e.preventDefault();
        dispatch(productCleared());
        dispatch(loadProducts(selectedClient.cluid, !selectedClient.personalId))
    };

    return (
        <React.Fragment>
            {selectedProduct ?
                <Row className={className}>
                    <Col md={12}>
                        <Product product={selectedProduct} className="bg-secondary"
                                 onRemove={selectedClient ? handleLoadClientProducts : handleClearClicked}/>
                    </Col>
                </Row>
                : null}
        </React.Fragment>
    );
};

export default ProductDetail;
