import React from "react";
import FormGroup from "reactstrap/es/FormGroup"
import Label from "reactstrap/es/Label";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

const TaskFormVertical = (props) => {

    const getClasses = () => {
        let classes = [];
        classes.push(props.className ? props.className : '');
        return classes.join(' ');
    };

    const getLabelClasses = () => {
        let classes = [];
        classes.push("d-block");
        classes.push(props.labelClassName ? props.labelClassName : '');
        return classes.join(' ');
    };

    const content = (id, label, children) => {
        return (
            <React.Fragment>
                <Label for={id} className={getLabelClasses()}>
                    {label}
                    {props.popover ?
                        <FontGeorgeIcon icon="help" popover={props.popover} id={"label-hint-" + props.id} width={23}
                                        height={23} className="ml-1 hint"/>
                        : null}
                </Label>
                {children}
            </React.Fragment>
        )
    };

    return (
        <React.Fragment>
            <FormGroup id={props.id} className={getClasses()}>
                {!props.icon ?
                    content(props.id, props.label, props.children)
                    :
                    <React.Fragment>
                        <Row>
                            <Col className="col-md-auto pr-0 my-auto smart-icon">
                                <FontGeorgeIcon icon={props.icon} width={35} height={35}/>
                            </Col>
                            <Col className="pl-2">
                                {content(props.id, props.label, props.children)}
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </FormGroup>
        </React.Fragment>
    )
};

export default TaskFormVertical;