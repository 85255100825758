import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {Card} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";
import moment from "moment";
import TaskFormVertical from "../form/taskFormVertical";

const Representative = ({representative, hoverState, setHoverState, selectedItem, setRepresentative}) => {
    return (
        <React.Fragment>
            <Card className={`my-1 ${hoverState === representative.customerId && "active-side-card"} ${selectedItem === representative.customerId ? 'bg-info' : ''}`} onClick={() => setRepresentative(representative.customerId)} onMouseEnter={() => setHoverState(representative.customerId)} onMouseLeave={() => setHoverState("")}>
                <CardBody className="py-3">
                    <Row>
                        <Col md="auto" className="my-auto pl-0">
                            <TaskFormVertical label={`${representative.degree ? representative.degree : ""} ${representative.firstname} ${representative.lastname} ${representative.additionalDegree ? representative.additionalDegree : ""}`}
                                              labelClassName="mb-3 text-break font-weight-bold font-size-12 name d-block">
                                <span
                                    className="text-break text-lowercase font-weight-bolder font-size-12 name d-block">{moment(representative.birthDate, "YYYYMMDD").format("DD.MM.YYYY")}</span>
                            </TaskFormVertical>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Representative;