import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import TaskFormVertical from "./form/taskFormVertical";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {
    recognize,
    searchClient,
    searchProduct,
    searchQueryAction,
    searchQueryChanged
} from "../core/actions/searchActions";
import {
    CLIENT_ENTITY_TYPES,
    PRODUCT_ENTITY_TYPES,
    STARTER_SEARCH_INPUT_EVENT
} from "../core/consts";
import Client from "./client/client";
import Product from "./product/product";
import StartInput from "./form/startInput";
import {fireEvent} from "../core/actions/eventActions";
import EmptyInfo from "./feedback/EmptyInfo";
import AlertInfo from "./feedback/AlertInfo";

const Search = ({style}) => {
    const dispatch = useDispatch();
    const query = useSelector(state => state?.search?.query);
    const searchLoading = useSelector(state => state?.search?.loading);
    const searchLoaded = useSelector(state => state?.search?.loaded);
    const clientLoading = useSelector(state => state?.client?.loading);
    const clientFailed = useSelector(state => state?.client?.failed);
    const activeProductsLoading = useSelector(state => state?.product?.activeProductsLoading);
    const historyProductsLoading = useSelector(state => state?.product?.historyProductsLoading);
    const cardProductsLoading = useSelector(state => state?.product?.cardProductsLoading);

    const productFailed = useSelector(state => state?.product?.failed);
    const searchFailed = useSelector(state => state?.search?.failed);

    const searchData = useSelector(state => state?.search?.data);
    const clientData = useSelector(state => state?.client?.data);
    const productData = useSelector(state => state?.product?.data);

    const fromTablet = useSelector(state => state?.user?.fromTablet);

    useEffect(() => {
        if(fromTablet){
            dispatch(recognize());
        }
    }, [fromTablet]);

    const handleChangeQuery = (e) => {
        dispatch(searchQueryChanged(e.target.value));
    };

    const handleSelectClient = (entity) => {
        dispatch(searchClient(entity));
        dispatch(dispatch(searchQueryAction([], false)))
    };

    const handleSelectProduct = (entity) => {
        dispatch(searchProduct(entity));
        dispatch(dispatch(searchQueryAction([], false)))
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            dispatch(recognize());
        }
    };

    const handleIconClick = () => {
        dispatch(recognize());
    };

    const isAnythingLoading = () => {
        return searchLoading || clientLoading || activeProductsLoading || historyProductsLoading || cardProductsLoading;
    };

    const isNoResult = () => {
        return (!searchData || searchData.length < 1) &&
            (!clientData || clientData.length < 1) &&
            (!productData || productData.length < 1)
    };

    const isAnythingFailed = () => {
        return clientFailed || productFailed || searchFailed;
    };

    const promptForSearch = () => {
        return isNoResult() && !searchLoaded && !isAnythingLoading() && !isAnythingFailed();
    };

    const promptForNoResults = () => {
        return isNoResult() && searchLoaded && !isAnythingLoading() && !isAnythingFailed();
    };

    const promptForError = () => {
        return searchFailed && !isAnythingLoading();
    };

    const onSearchDelay = () => {
        dispatch(fireEvent(STARTER_SEARCH_INPUT_EVENT));
    };

    const getSearchPromptText = () => {
        return (
            <>Není vybrán
                <span className="font-size-13 font-weight-bold"> produkt</span> nebo
                <span className="font-size-13 font-weight-bold"> klient.</span>
            </>
        )
    };

    return (
        <React.Fragment>
            <Row id="search" className="filter-section">
                <Col md={12}>
                    <TaskFormVertical label="Vyhledejte klienta nebo produkt" className="px-0"
                                      popover="Vyhledávejte podle RČ, IČO, cluidu, emailu, nebo čísla produktu." id="search-label">
                        <StartInput clearable={!fromTablet} value={query} size="lg" onChange={handleChangeQuery}
                                    icon="search"
                                    delay={500}
                                    placeholder={"Vyhledávat"}
                                    onDelay={onSearchDelay}
                                    onKeyDown={handleKeyDown}
                                    onIconClick={handleIconClick}
                                    id="entity-search-input"
                                    disabled={fromTablet}/>
                    </TaskFormVertical>
                </Col>
            </Row>
            {promptForNoResults() ?
                <Row className="mt-3">
                    <Col>
                        <EmptyInfo icon="search-xl" text="Nenašli jsme žádné výsledky"/>
                    </Col>
                </Row>: null}

            {promptForSearch() ?
                <Row className="mt-3">
                    <Col>
                        <EmptyInfo icon="error-xl" text={getSearchPromptText()}/>
                    </Col>
                </Row>: null}

            {promptForError() ?
                <Row>
                    <Col className="mt-4">
                        <AlertInfo color="warning"
                                   label="Při vyhledávání došlo k chybě."
                                   hideClose={true}/>
                    </Col>
                </Row>: null}

            {searchData && searchData.length > 0 ?
                <Row style={style}>
                    <Col md={12}>
                        {searchData.filter(d => CLIENT_ENTITY_TYPES.some(e => e === d.type)).map(d =>
                            <Client key={d.ref} compressed={true} entity={d} onClick={handleSelectClient}
                                    className="mb-2"/>
                        )}

                        {searchData.filter(d => PRODUCT_ENTITY_TYPES.some(e => e === d.type)).map(d =>
                            <Product key={d.ref} compressed={true} entity={d} onClick={handleSelectProduct}
                                     className="mb-2"/>
                        )}
                    </Col>
                </Row>
                : null}
        </React.Fragment>
    );
};

export default Search;
