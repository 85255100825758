import {VIEWPORT_UPDATED} from "../consts";

export const vieportUpdate = (width, height) => (
    {type: VIEWPORT_UPDATED, width, height}
);

export function fireViewportInfo(width, height) {
    return (dispatch) => {
        dispatch(vieportUpdate(width, height));
    };
}