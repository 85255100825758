import {useDispatch} from "react-redux";
import {fireHideAlert} from "../../core/actions/alertActions";
import {
    ALERT_DANGER,
    ALERT_INFO,
    ALERT_SUCCESS,
    ALERT_WARNING,
    ICON_DANGER, ICON_INFO,
    ICON_SUCCESS,
    ICON_WARNING
} from "../../core/consts";
import {Alert} from "reactstrap";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import Button from "reactstrap/es/Button";
import CountdownInfo from "./CountdownInfo";
import React from "react";

const AlertInfo = ({id, label, onClick, color, actionLabel, timeout, hideClose, className}) => {

    const dispatch = useDispatch();
    const ICON_DIMENSION = 25;
    const TOGGLE_DIMENSION = 20;

    const closeAlert = (alert) => {
        dispatch(fireHideAlert(alert))
    };

    const getIcon = (color) => {
        switch (color) {
            case ALERT_DANGER:
                return ICON_DANGER;
            case ALERT_SUCCESS:
                return ICON_SUCCESS;
            case ALERT_WARNING:
                return ICON_WARNING;
            case ALERT_INFO:
                return ICON_INFO;
            default:
                return ICON_INFO
        }
    };

    const getExpireTime = (timeout) => {
        let futureMillis = new Date().getTime() + timeout;
        return new Date(futureMillis);
    };

    return (
        <Alert key={id} color={color} className={className}>
            <Row>
                <Col className="col-md-auto my-auto">
                    <FontGeorgeIcon icon={getIcon(color)}
                                    width={ICON_DIMENSION}
                                    height={ICON_DIMENSION}/>
                </Col>
                <Col className="text-left my-auto pl-0">
                    <Row>
                        <Col className="font-size-115">
                            {label}
                        </Col>
                    </Row>
                    {onClick ?
                        <Row className="my-2">
                            <Col className="col-md-auto">
                                <Button color={color} onClick={onClick}>{actionLabel}</Button>
                            </Col>
                            <Col className="text-right font-weight-bold my-auto">
                                <CountdownInfo expireTime={getExpireTime(timeout)} className="font-size-13"/>
                            </Col>
                        </Row> : null}
                </Col>
                {!onClick && !hideClose?
                    <Col className="col-md-auto">
                        <FontGeorgeIcon icon="error"
                                        width={TOGGLE_DIMENSION}
                                        height={TOGGLE_DIMENSION}
                                        className="selectable"
                                        onClick={() => closeAlert({id})}/>
                    </Col> : null}
            </Row>
        </Alert>
    )
};

export default AlertInfo;