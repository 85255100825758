import {analyzeUrl, extractParamFromURL} from "../utils";
import {recognize, searchQueryChanged} from "./searchActions";
import {loadByExpression, loadCaseTypes, loadSingleProduct} from "../actions";
import {PRESELECTED_BOTH, PRESELECTED_CGPID, PRESELECTED_CLUID} from "../consts";

export function chainActions () {
    return (dispatch) => {
        let preselected = analyzeUrl();
        let promises = [];

        promises.push(dispatch(loadCaseTypes(true)));
        //1] Only client is preselected
        if(preselected === PRESELECTED_CLUID){
            let cluid = extractParamFromURL('cluid');
            dispatch(searchQueryChanged(cluid));
            promises.push(dispatch(recognize(true)));
        }

        //2] Only cgp is preselected
        if(preselected === PRESELECTED_CGPID){
            let cgpId = extractParamFromURL('cgpId');
            promises.push(dispatch(loadSingleProduct(cgpId, true)));
        }

        //3] Both cgp&cluid is preselected
        if(preselected === PRESELECTED_BOTH){
            let cluid = extractParamFromURL('cluid');
            let cgpId = extractParamFromURL('cgpId');
            dispatch(searchQueryChanged(cluid));
            promises.push(dispatch(recognize(true, true)));
            promises.push(dispatch(loadSingleProduct(cgpId, true)));
        }

        //4] If everything loaded, fire expression
        Promise.all(promises).then(() => dispatch(loadByExpression()))
    }
}