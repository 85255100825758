import ModalBody from "reactstrap/es/ModalBody";
import Button from "reactstrap/es/Button";
import Modal from "reactstrap/es/Modal";
import React from "react";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalFooter from "reactstrap/es/ModalFooter";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

const DialogInfo = ({isOpen, color, icon, onSubmit, onToggle, header, info, submitLabel, toggleLabel = "Zavřít"}) => {
    const ICON_DIMENSION = 25;

    return (
        <Modal isOpen={isOpen} className={color}>
            <ModalHeader>
                <Row>
                    <Col className="col-md-1">
                        <FontGeorgeIcon icon={icon} width={ICON_DIMENSION} height={ICON_DIMENSION}/>
                    </Col>
                    <Col className="my-auto">
                        <span className="font-weight-bold font-size-12">{header}</span>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody className="py-0">
                <Row>
                    <Col className="offset-md-1 font-size-12">
                        {info}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {onToggle ?
                    <Button color={color} outline onClick={onToggle}>{toggleLabel}</Button> : null}

                {onSubmit ?
                    <Button color={color} onClick={onSubmit}>{submitLabel}</Button> : null}
            </ModalFooter>
        </Modal>
    )
};

export default DialogInfo;