import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {clientCleared} from "../../core/actions";
import {useDispatch, useSelector} from "react-redux";
import Client from "./client";
import ProductList from "../product/productList";
import AlertInfo from "../feedback/AlertInfo";
import RepresentativeList from "../representative/representativeList"

const ClientDetail = ({className}) => {
    const clientData = useSelector(state => state?.client?.data);
    const productData = useSelector(state => state?.product?.data);
    const clientProductsFailed = useSelector(state => state?.product?.activeProductsLoadingFailed || state?.product?.historyProductsLoadingFailed ||  state?.product?.cardProductsLoadingFailed);
    const dispatch = useDispatch();

    const handleClearClicked = (e) => {
        e.stopPropagation();
        dispatch(clientCleared())
    };

    return (
        <React.Fragment>
            {clientData ?
                <Row className={className}>
                    <Col md={12}>
                        <Client client={clientData} className="bg-secondary"
                                onRemove={handleClearClicked}/>
                    </Col>

                    {!productData ?
                        <>
                            <Col md={12} className="px-0">
                                <hr/>
                            </Col>
                            <Col md={12}>
                                <RepresentativeList />
                                <ProductList/>
                            </Col>
                        </>
                        : null}

                    {clientProductsFailed ?
                        <Col>
                            <AlertInfo color="warning"
                                       hideClose={true}
                                       label="Načítání klientových produktů selhalo"/>
                        </Col>
                        :null}
                </Row>
                : null}
        </React.Fragment>
    );
};

export default ClientDetail;
