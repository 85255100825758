import Resource from "../serverresource";
import {
    ENVIRONMENT_PROPERTIES_ERROR,
    ENVIRONMENT_PROPERTIES_LOADED,
    ENVIRONMENT_PROPERTIES_LOADING
} from "../consts";

export const environmentPropertiesLoading = () => (
    {type: ENVIRONMENT_PROPERTIES_LOADING}
);

export const environmentPropertiesLoaded = (properties) => (
    {type: ENVIRONMENT_PROPERTIES_LOADED, properties}
);

export const environmentPropertiesError = (error) => (
    {type: ENVIRONMENT_PROPERTIES_ERROR, error}
);

export function fireLoadEnvironment() {
    return (dispatch) => {
        dispatch(environmentPropertiesLoading());
        return fetch(Resource.getSesApi() + "/configuration")
            .then(properties => properties.json())
            .then(properties => dispatch(environmentPropertiesLoaded(properties)))
            .catch(err => {
                dispatch(environmentPropertiesError(err.message));
                console.log("Unable to load properties", err)
            });
    }
}


